import React from "react";
import { oneOf, bool, shape } from "prop-types";
import { Route, useLocation } from "react-router-dom";
import { SearchProvider } from "../../contexts/Search";
import InstitutionalHeader from "../InstitutionalHeader";
import InstitutionalFooter from "../InstitutionalFooter";
import AlternativeHeader from "../AlternativeHeader";
import Header from "../Header";
import Footer from "../Footer";
import styles from "./styles.module.scss";

//Enterprise
import HeaderEnterprise from "../../modules/Enterprise/components/Header";
import FooterEnterprise from "../../modules/Enterprise/components/Footer";
import AlternativeHeaderEnterprise from "../../modules/Enterprise/components/AlternativeHeader";
import { getParaEmpresasUrl } from "../../helpers/getParaEmpresasUrl";

const CustomRoute = ({ component: Component, options, ...rest }) => {
  // eslint-disable-next-line no-unused-vars
  const location = useLocation();
  const userToken = localStorage.getItem("@user_token");
  const {
    hideHeader,
    hideFooter,
    headerVersion,
    footerVersion,
    authenticated,
  } = options;

  if (location.pathname.includes("/para-empresas")) {
    const newUrl = getParaEmpresasUrl(location);
    window.location = newUrl;
    return;
  }

  if (authenticated && !userToken) {
    if (location.pathname.includes("/para-empresas")) {
      return window.location.href = "/para-empresas/autenticacao/login";
    };

    return window.location.href = "/autenticacao/login";
  }

  const renderHeader = () => {
    if (hideHeader) return;

    if (headerVersion === "simple") {
      return <AlternativeHeader />;
    }

    if (headerVersion === "simpleEnterprise") {
      return <AlternativeHeaderEnterprise />;
    }

    if (headerVersion === "institutional") {
      return <InstitutionalHeader />;
    }

    if (headerVersion === "enterprise") {
      return <HeaderEnterprise />
    }

    if (headerVersion === "hideSearch") {
      return <Header hideSearch />
    }

    return <Header />;
  };

  const renderFooter = () => {
    if (hideFooter) return;

    if (footerVersion === "institutional") {
      return <InstitutionalFooter />;
    }

    if (footerVersion === "enterprise") {
      return <FooterEnterprise />
    }

    return <Footer />;
  };

  return (
    <div className={styles.wrapper}>
      <SearchProvider>
        {renderHeader()}
      </SearchProvider>
      <Route {...rest}>
        <Component />
      </Route>
      {renderFooter()}
    </div>
  );
};

CustomRoute.propTypes = {
  options: shape({
    hideHeader: bool,
    headerVersion: oneOf(["default", "institutional", "simple", "enterprise", "simpleEnterprise", "hideSearch"]),
    hideFooter: bool,
    footerVersion: oneOf(["default", "institutional", "enterprise"]),
  }),
};

CustomRoute.defaultProps = {
  options: {
    hideHeader: false,
    headerVersion: "default",
    hideFooter: false,
    footerVersion: "default",
  },
};

export default CustomRoute;
