import React, { useRef, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { Input } from '../../../../components/NewInput';
import SquareButton from '../../../../components/SquareButton';
import SectionTitle from '../../../../components/SectionTitle';
import { BooleanCheckbox } from '../../../../components/NewInput';
import useAxios from '../../../../hooks/useAxios';
import { useUserContext } from '../../../../contexts/User';
import validationDictionary from '../../../../constants/validationDictionary';
import { focusElement, parseEmail } from '../../../../helpers';
import FieldsGrid from "../../components/FieldsGrid";
import { useSnackBarContext } from "../../../../contexts/SnackBar";
import AuthenticationParagraph from "../AuthenticationParagraph";
import { FileUpload } from '../../../../components/FileUpload';
import InputCheckBox from '../../../../components/InputCheckBox';
import SelectInput from '../../../../components/SelectInput';

const SignUpForm = () => {
  const formRef = useRef(null);
  const { exec, loading } = useAxios();
  const history = useHistory();
  const { updateInkoins, setUserData } = useUserContext();
  const { showSnackBar } = useSnackBarContext();
  const [hasDisability, setHasDisability] = useState(null);
  const [hasDisabilityError, setHasDisabilityError] = useState(false);
  const [disabilityType, setDisabilityType] = useState(null);
  const [disabilityTypeError, setDisabilityTypeError] = useState(false);
  const [reportFile, setReportFile] = useState(null);
  const [resumeFile, setResumeFile] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const disabilities = [
    {'value': 1,'label': 'Física'},
    {'value': 2,'label': 'Visual'},
    {'value': 3,'label': 'Auditiva'},
    {'value': 4,'label': 'Intelectual'},
    {'value': 5,'label': 'Psicossocial'},
    {'value': 6,'label': 'Reabilitado'}
  ];

  const blockCopyPaste = (e) => {
    e.preventDefault();
  };

  const handleSubmit = async (data) => {
    setOpenSnackbar(true);
    try {
      formRef.current.setErrors({});

      showSnackBar({
        timeout: 5000,
        text: 'Validando dados...',
        loading
      });

      const cleanPhoneNumber = (value) => {
        if (!value) return '';
        return value.replace(/[^\d]/g, '');
      }

      if (hasDisability === null) setHasDisabilityError(true);

      if (hasDisability === 1 && disabilityType === null) setDisabilityTypeError(true);

      const schema = Yup.object().shape({
        name: Yup.string()
          .test('surname-length', 'O sobrenome deve ter pelo menos 2 caracteres', value => {
            if (!value) return false;
            const parts = value.trim().split(' ');
            return parts.length >= 2 && parts[1].length >= 2;
          })
          .test('name-length', 'O nome deve ter pelo menos 3 caracteres', value => {
            if (!value) return false;
            const parts = value.trim().split(' ');
            return parts[0].length >= 3;
          })
          .matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'O campo não deve conter caracteres especiais')
          .required('Nome e sobrenome são obrigatórios'),
        phone: Yup.string()
          .transform(value => cleanPhoneNumber(value))
          .test('valid-number', 'O telefone deve conter 8 dígitos após o 9', value => {
            const number = value.substring(3);
            return /^\d{8}$/.test(number);
          })
          .test('valid-nine', 'Está faltando o digito 9 antes do número', value => value[2] === '9')
          .test('valid-ddd', 'DDD inválido', value => {
            const ddd = value.substring(0, 2);
            return /^(1[1-9]|[2-9]\d)$/.test(ddd);
          })
          .test('min-length', 'O telefone deve conter 11 dígitos', value => value.length === 11)
          .required('O telefone é obrigatório'),
        email: Yup.string()
        .transform(parseEmail)
        .test('email', validationDictionary.INVALID_EMAIL, value => {
          if (!value) return false;
          return /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(value);
        })
        .required(validationDictionary.INVALID_EMAIL),
        confirm_email: Yup.string()
          .transform(parseEmail)
          .oneOf([Yup.ref('email'), null], 'O e-mail deve ser igual ao informado acima')
          .required('Confirme seu e-mail'),
        accepted_terms: Yup.bool()
          .oneOf([true], validationDictionary.TERMS_ARE_REQUIRED),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();

      formData.append('phone', data.phone.replace(/\D/g, ''));
      formData.append('email', parseEmail(data.email));
      formData.append('name', data.name);
      formData.append('hasDisability', hasDisability);
      hasDisability === 1 && formData.append('disabilityType', disabilityType);

      reportFile && formData.append('report', reportFile);
      resumeFile && formData.append('resume', resumeFile);

      data.phone = data.phone.replace(/\D/g, '')

      const response = await exec({
        method: 'POST',
        url: '/v2/register/candidate',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const { user, token, data: dataError, error } = response;

      if (error) {
       setOpenSnackbar(false);
        showSnackBar({
          timeout: 5000,
          text: dataError.message,
          error
        });
        return;
      }

      if (token && user.id && !error) {
        setUserData(user);

        // Ao voltar os Inkoins descomentar esse código
        // if (user.type === 'PF') {
        //   updateInkoins(0);
        //   localStorage.setItem('@user_inkoins', JSON.stringify(0));
        // }

        localStorage.setItem('@user_token', token);
        localStorage.setItem('@user_data', JSON.stringify(user));

        setOpenSnackbar(false);

        if (hasDisability === 1) return history.push('/autenticacao/cadastro/candidato/cadastro-completo-pcd');

        return history.push('/autenticacao/cadastro/candidato/cadastro-completo');
      }

      const errors = dataError.errors;
      const validationErrors = {};

      Object.keys(errors).forEach((err) => {
        validationErrors[err] = errors[err][0];
      });

      formRef.current.setErrors(validationErrors);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    focusElement('[data-testid="section-title"]');
  }, []);

  useEffect(() => {
    if (hasDisability === 0 || hasDisability === 1) setHasDisabilityError(false);
  }, [hasDisability]);

  useEffect(() => {
    if (disabilityType) setDisabilityTypeError(false);
  }, [disabilityTypeError, disabilityType]);

  useEffect(() => {
    if (openSnackbar) {
      showSnackBar({
        timeout: 10000,
        text: 'Validando dados, por favor aguarde.',
        loading
      });
    }
  }, [openSnackbar]);

  return (
    <Form ref={formRef} onSubmit={handleSubmit} id='contact-form'>
      <SectionTitle
        small
        centered
        level='h2'
        children='Crie sua conta <b>de candidato</b>'
        dataTest='signup-title'
      />
      <AuthenticationParagraph>
        Bora criar sua conta na Inklua, em poucos cliques o seu perfil já estará disponível para 
        centenas de recrutadores!
      </AuthenticationParagraph>
      <FieldsGrid>
        <Input
          label='Nome Completo'
          name='name'
          placeholder='Digite o seu nome completo'
          type='text'
        />
        <Input
          label='Celular'
          name='phone'
          placeholder='Exemplo: (11) 9 9999-9999'
          type='text'
          data-test='signup-phone'
          mask='(99) 9 9999-9999'
        />
        <Input
          label='E-mail'
          name='email'
          placeholder='Digite o seu e-mail'
          type='text'
          data-test='signup-email'
          onPaste={blockCopyPaste}
        />
        <Input
          label='Confirme o E-mail'
          name='confirm_email'
          placeholder='Digite o seu e-mail'
          type='text'
          data-test='signup-email'
          onPaste={blockCopyPaste}
        />
        <InputCheckBox
          label='Você possui alguma deficiencia?'
          options={[
            { label: 'Sim', value: 1 },
            { label: 'Não', value: 0 }
          ]}
          onChange={({ value }) => setHasDisability(value)}
          checked={hasDisability}
          name='disability'
          error={hasDisabilityError}
        />
        { hasDisability === 1 && (
          <>
            <SelectInput
              name='disability-type'
              label='Qual o tipo de deficiencia'
              setter={(value) => setDisabilityType(value)}
              placeholder='Selecione o tipo da sua deficiencia'
              keys={{ label: 'label', value: 'value' }}
              options={disabilities}
              error={disabilityTypeError}
            />
            <FileUpload
              name='report'
              label={'Anexe seu laudo (opicional)'}
              formatsAllowed={'DOC, DOCX, PDF, JPG, JPEG e PNG'}
              placeholder='Clique aqui para anexar seu laudo'
              setFile={setReportFile}
            />
          </>
        )}
        <FileUpload
          name='resume'
          label={'Anexe seu currículo (opicional)'}
          formatsAllowed={'DOC, DOCX, PDF, JPG, JPEG e PNG'}
          placeholder='Clique aqui para anexar seu currículo'
          setFile={setResumeFile}
        />
        <BooleanCheckbox name='accepted_terms' dataTest='accept-terms'>
          Li e aceito a{' '}
          <Link target='_blank' to='/politica-de-privacidade'>
            Política de Privacidade
            <br /> e Retenção de Dados Pessoais.
          </Link>
        </BooleanCheckbox>
      </FieldsGrid>
      <SquareButton
        disabled={loading}
        text='Criar conta'
        type='submit'
        dataTest='signup-submit'
      />
    </Form>
  );
};

export default SignUpForm;
