import React from 'react'
import CheckboxFilter from '../Checkbox';
import styles from './styles.module.scss';

const InputCheckBox = ({ label, onChange, checked, options, error }) => {
  return (
    <div className={styles.wrapper}>
      <p>{label}</p>
      <div className={styles.checkBoxFlex}>
        {options?.map((option) => (
          <CheckboxFilter
            key={option.value}
            name={option.label}
            label={option.label}
            value={option.value}
            onChange={onChange}
            checked={checked === option.value}
            error={error}
          />
        ))}
      </div>
      {error && <span className={styles.error}>Selecione uma opção</span>}
    </div>
  )
}

export default InputCheckBox;