import React from 'react'
import { Radio } from '../NewInput'
import styles from './styles.module.scss'

const SimpleRadioInput = ({ label, name, options, tooltipText, parentRef }) => {
  return (
    <div className={styles.wrapper}>
      <label htmlFor={name}>{label}</label>
      <Radio
        name={name}
        options={options || [
          { id: true, label: 'Sim' },
          { id: false, label: 'Não' },
        ]}
        parentRef={parentRef}
        displayError
      />
    </div>
  )
}

export default SimpleRadioInput