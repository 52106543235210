import React from "react";
import { Link, useHistory } from "react-router-dom";

import SquareButton from "../../../../components/SquareButton";
import SectionTitle from "../../../../components/SectionTitle";
import useAxios from "../../../../hooks/useAxios";

import AuthenticationParagraph from "../../components/AuthenticationParagraph";

const EmailValidatedSuccesfully = () => {
  const history = useHistory();
  const { exec, loading } = useAxios();

  const handleSubmit = async () => {
    const response = await exec({
      useCredentials: true,
      method: "POST",
      url: "/user/accept_terms",
      data: {
        accepted_terms: true,
      },
    });

    if (response.data) {
      response.data.email_verified_at = true;
      localStorage.setItem("@user_data", JSON.stringify(response.data));
      history.push("/");
    }
  };

  return (
    <>
      <SectionTitle
        small
        centered
        level="h2"
        children="Estamos quase lá, <b>falta só aceitar</b>"
      />
      <AuthenticationParagraph>
        Ao continuar você atesta que leu e concorda com nossa{" "}
        <Link to="/politica-de-privacidade">
          Política de Privacidade
          <br /> e Retenção de Dados Pessoais.
        </Link>
      </AuthenticationParagraph>
      <SquareButton
        onClick={handleSubmit}
        type="button"
        text="Continuar"
        disabled={loading}
      />
    </>
  );
};

export default EmailValidatedSuccesfully;
