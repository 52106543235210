import React, { useRef } from "react";
import { Form } from "@unform/web";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

import { Input } from "../../../../components/NewInput";
import SquareButton from "../../components/SquareButton";
import SquareLinkButton from "../../components/SquareLinkButton";
import SectionTitle from "../../../../components/SectionTitle";
import useAxios from "../../../../hooks/useAxios";
import { useUserContext } from "../../../../contexts/User";
import validationDictionary from "../../../../constants/validationDictionary";

import AuthenticationParagraph from "../../../authentication/components/AuthenticationParagraph";
import FieldsGrid from "../../../authentication/components/FieldsGrid";

import styles from "./styles.module.scss";
import { useSnackBarContext } from "../../../../contexts/SnackBar";
import { parseEmail } from "../../../../helpers";

const SignInFormEnterprise = () => {
  const formRef = useRef(null);
  const { exec, loading } = useAxios();
  const history = useHistory();
  const { setUserData } = useUserContext();
  const { showSnackBar } = useSnackBarContext();

  const handleSubmit = async (data) => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .transform(parseEmail)
          .email(validationDictionary.INVALID_LOGIN)
          .required(validationDictionary.INVALID_LOGIN),
        password: Yup.string()
          .min(8, validationDictionary.INVALID_LOGIN)
          .required(validationDictionary.INVALID_LOGIN),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        method: "POST",
        url: "/user/login",
        data: { ...data, email: parseEmail(data.email) },
      });

      if (response.error && response.status === 401) {
        showSnackBar({
          timeout: 5000,
          text: "E-mail ou senha incorretos.",
          error: true
        });
        return;
      }

      if (!response.token) {
        formRef.current.setErrors({
          email: response.data.message,
          password: response.data.message,
        });
        return;
      };

      if (response.token && !response.user.accepted_terms) {
        setUserData(response.user);
        localStorage.setItem(
          "@user_inkoins",
          JSON.stringify(response.user.wallet.coins)
        );
        localStorage.setItem("@user_token", response.token);
        localStorage.setItem("@user_data", JSON.stringify(response.user));
        history.push("/para-empresas/autenticacao/aceite-os-termos-de-uso");
        return;
      };

      if (response.token && !response.user.email_verified_at) {
        localStorage.setItem("@user_token", response.token);
        localStorage.setItem("@user_data", JSON.stringify(response.user));
        // history.push("/para-empresas/autenticacao/confirme-seu-email");
        return;
      };

      localStorage.setItem("@user_token", response.token);
      localStorage.setItem("@user_data", JSON.stringify(response.user));

      if (formRef.current) formRef.current.reset();

      if (response.user.type === "PJ" && !response.user.inkluer) return history.push("/para-empresas");

      if (response.user.type === "PF") return history.push("/");

      window.location = window.location.origin + "/recrutador/minhas-vagas";
    } catch (error) {
      const validationErrors = {};

      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((error) => {
          validationErrors.email = validationDictionary.INVALID_LOGIN;
        });
        formRef.current.setErrors(validationErrors);
      } else {
        showSnackBar({
          timeout: 5000,
          text: "Houve um problema, tente novamente.",
          error: true
        });
      }
    }
  };

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      id="contact-form"
      className={styles.formWrapper}
    >
      <SectionTitle
        small
        centered
        level="h2"
        children="Seja bem-vinda(o) <b>à Inklua para empresas</b>"
      />
      <AuthenticationParagraph>
        Crie ou acesse sua conta para utilizar
        <br /> nossas soluções para sua empresa.
      </AuthenticationParagraph>
      <FieldsGrid>
        <Input
          label="E-mail"
          name="email"
          placeholder="Digite seu e-mail"
          type="text"
        />
        <Input
          label="Senha"
          name="password"
          placeholder="Digite sua senha"
          type="password"
        />
      </FieldsGrid>
      <Link
        className={styles.forgotPasswordLink}
        to="/para-empresas/autenticacao/esqueci-minha-senha"
      >
        Esqueci minha senha
      </Link>
      <FieldsGrid size="small">
        <SquareButton disabled={loading} text="Entrar" type="submit" />
        <SquareLinkButton
          type="outlined"
          text="Criar uma conta"
          url="/para-empresas/autenticacao/cadastro/recrutador"
        />
      </FieldsGrid>
    </Form>
  );
};

export default SignInFormEnterprise;
