import { useRef, useState, useEffect } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import {
  FileInput,
  errorParser,
  // eslint-disable-next-line no-unused-vars
  validateFileField,
  Input,
  CurrencyInput,
} from "../../../../components/NewInput";
import SquareButton from "../../../../components/SquareButton";
import SelectInput from "../../../../components/SelectInput";
import { states, genders, races } from "../../../../libs/data";
import validationDictionary from "../../../../constants/validationDictionary";
import AutoCompleteInput from "../../../../components/AutoCompleteInput";
import useAxios from "../../../../hooks/useAxios";
import { useStatesAndCities } from "../../../../hooks/useStatesAndCities";
import { onlyNumbers } from "../../../../helpers/format";
import { Lock } from "../../../../libs/icons";
import PasswordButton from "../../components/PasswordButton";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { toDate, focusElement, toCurrency } from "../../../../helpers";
import SquareLinkButton from "../../../../components/SquareLinkButton";

import { SectionTitle } from "../../components/SectionTitle";
import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";
import { useSnackBarContext } from "../../../../contexts/SnackBar";

import styles from "./styles.module.scss";
import { isValidDate } from "../../../Candidate/utils/isValidDate";

const CandidateEditPersonalData = () => {
  const { exec, loading } = useAxios();
  const { loading: isLocationLoading, getCities: fetchCityList } =
    useStatesAndCities();
  const history = useHistory();
  const [city, setSelectedCity] = useState(null);
  const [state, setState] = useState(null);
  const [gender, setGender] = useState(null);
  const [race, setRace] = useState(null);
  const formRef = useRef();
  const [availableCities, setAvailableCities] = useState([]);
  const [pageData, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { showSnackBar } = useSnackBarContext();

  const fetchData = async () => {
    try {
      const { user } = await exec({
        method: "GET",
        url: `/my-account`,
        useCredentials: true,
      });

      if (user.state) {
        setState(user.state);
      }

      if (user.city) {
        setSelectedCity(user.city);
      }

      if (user.race) {
        setRace(user.race);
      }

      if (user.gender) {
        setGender(user.gender);
      }

      setData(user);
      setIsLoading(false);
    } catch (error) {
      console.error("[INKLUA]", { error });
    }
  };

  const getCities = async () => {
    try {
      const response = await fetchCityList(Number(state));

      setAvailableCities(response);
    } catch (error) {
      console.error("[INKLUA]", { error });
    }
  };

  const handleSelectCity = (value) => {
    if (value === '') setSelectedCity('');

    const filteredCities = availableCities.filter((city) => {
      const cityFromData = city.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ", "_").toLowerCase();
      const cityValue = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ", "_").toLowerCase();

      return cityFromData === cityValue;
    });

    if (!filteredCities.length) return "";

    return filteredCities[0].id;
  };

  const handleSubmit = async (data) => {
    try {
      data.state = state;
      data.gender = gender;
      data.race = race;
      data.city = handleSelectCity(data.city);
      data.phone = onlyNumbers(data.phone);
      data.salary = Number(data.salary);

      const schema = Yup.object().shape({
        birthdate: Yup.string()
          .test(
            "birthdate",
            validationDictionary.INVALID_BIRTHDATE_AGE,
            (value) => {
              const date = value.split('/');
              const today = new Date();
              const age = today.getFullYear() - parseInt(date[2], 10);

              return age >= 15 && age <= 80 && isValidDate(value);;
            }
          )
          .required(validationDictionary.INVALID_BIRTHDATE),
        state: Yup.number()
          .oneOf(
            states.brazil.map((s) => s.id),
            validationDictionary.INVALID_STATE_SELECT
          )
          .required(validationDictionary.INVALID_STATE_SELECT)
          .nullable(),
        city: Yup.number()
          .typeError('Selecione uma cidade válida')
          .required(validationDictionary.INVALID_CITY_SELECT)
        ,
        race: Yup.number()
          .oneOf(
            races.map((raceOrColor) => raceOrColor.value),
            validationDictionary.INVALID_RACE
          )
          .required(validationDictionary.INVALID_RACE)
          .nullable(),
        gender: Yup.number()
          .oneOf(
            genders.map((genderListItem) => genderListItem.value),
            validationDictionary.INVALID_GENDER
          )
          .required(validationDictionary.INVALID_GENDER)
          .nullable(),
        phone: Yup.string()
          .min(10, validationDictionary.INVALID_PHONE)
          .max(13, validationDictionary.INVALID_PHONE)
          .required(validationDictionary.INVALID_PHONE),
        salary: Yup.number()
          .min(1, validationDictionary.INVALID_SALARY)
          .required(validationDictionary.INVALID_SALARY),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        useCredentials: true,
        method: "PUT",
        url: "/candidate/personal-data",
        data,
      });

      if (response.error) {
        showSnackBar({
          timeout: 5000,
          text: response.data.message,
          error: true
        });
        return;
      }

      history.push("/candidato/minha-conta");
    } catch (err) {
      console.error("[INKLUA]", { err });

      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        const parsedErrors = errorParser(validationErrors);

        formRef.current.setErrors(parsedErrors);
      }
    }
  };

  useEffect(() => {
    getCities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    fetchData();

    focusElement('[data-testid="account-section-title"]');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <AuthenticationWrapper>
      <SectionTitle>Dados pessoais</SectionTitle>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        autoComplete="off"
        className={styles.form}
        initialData={{
          name: pageData.name,
          lastname: pageData.lastname,
          email: pageData.email,
          phone: pageData.phone,
          salary: toCurrency({ value: pageData.salary, hideCurrencySymbol: true }),
          birthdate: toDate(pageData.birthdate, "DD/MM/YYYY"),
          portfolio: pageData.portfolio,
          linkedin: pageData.linkedin,
          neighborhood: pageData.neighborhood,
          race: pageData.race,
          gender: pageData.gender,
          resume: pageData.cv_path,
          state: pageData.state,
          city: pageData.city,
        }}
      >
        <Input
          label="Nome"
          name="name"
          placeholder="Digite seu nome"
          type="text"
        />
        <Input
          label="Sobrenome"
          name="lastname"
          placeholder="Digite seu sobrenome"
          type="text"
        />
        <Input
          label="Data de nascimento"
          name="birthdate"
          placeholder="Insira a sua data de nascimento"
          type="text"
          mask="99/99/9999"
          ariaLabel="preenchimento da data de nascimento no formato de dois digitos para o dia, dois digitos para o mes e quatro digitos para o ano. Só pode ser inserido números neste campo"
        />
        <SelectInput
          name="gender"
          label="Gênero"
          placeholder="Selecione o seu gênero"
          setter={setGender}
          keys={{ label: "label", value: "value" }}
          options={genders}
          defaultValue={pageData.gender}
        />
        <SelectInput
          name="race"
          label="Cor/Raça"
          placeholder="Selecione a sua cor/raça"
          setter={setRace}
          keys={{ label: "label", value: "value" }}
          options={races}
          defaultValue={pageData.race}
        />
        <SelectInput
          name="state"
          label="Estado"
          placeholder="Selecione um estado"
          setter={setState}
          keys={{ label: "name", value: "id" }}
          options={states.brazil}
          disabled={loading}
          hidePlaceholder={false}
          defaultValue={pageData.state}
        />
        <AutoCompleteInput
          placeholder="Digite o nome da cidade"
          name="city"
          label="Cidade"
          options={loading ? [] : availableCities.map((city) => city.name)}
          fetch={() => { }}
          setter={() => { }}
          disabled={isLocationLoading || !availableCities.length}
          defaultValue={availableCities.find((city) => city.id === pageData.city)?.name}
        />
        <Input
          label="Bairro"
          name="neighborhood"
          placeholder="Digite o nome do bairro"
          type="text"
          enableFlag
        />
        <Input
          label="Celular ou telefone"
          name="phone"
          placeholder="Insira um celular ou telefone"
          type="text"
          mask="(99) 9999-99999"
        />
        <Input
          label="E-mail"
          name="email"
          placeholder="Digite seu e-mail"
          type="text"
          disabled
          icon={<Lock color="#8F9BB3" />}
        />
        <PasswordButton />
        <CurrencyInput
          label="Pretensão salarial"
          name="salary"
          placeholder="Insira sua pretensão salarial"
          type="text"
        />
        <FileInput
          name="resume"
          label="Anexar currículo"
          placeholder="Clique aqui para anexar o currículo"
          suffix="Formatos: DOC, DOCX, PDF, JPG, JPEG e PNG."
          validExtensions={["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "pdf", "jpeg", "jpg", "png"]}
          defaultValue={pageData.cv_path}
          enableFlag
        />
        <Input
          label="Linkedin"
          name="linkedin"
          placeholder="Insira o link do seu perfil LinkedIn"
          type="text"
          enableFlag
        />
        <Input
          label="Portfolio"
          name="portfolio"
          placeholder="Insira o link do seu portfólio"
          type="text"
          enableFlag
        />
        <SquareButton
          disabled={loading}
          text="Concluir"
          type="submit"
          testID="submit-personal-data-button"
        />
      </Form>
      <SquareLinkButton
        testID="back-to-my-account"
        text="Voltar"
        url="/candidato/minha-conta"
        type="ghost"
      />
    </AuthenticationWrapper>
  );
};

export { CandidateEditPersonalData };
