import React from "react";

import InstagramIcon from "./Instagram";
import LinkedinIcon from "./Linkedin";
import FacebookIcon from "./Facebook";
import LeftArrowIcon from "./LeftArrow";
import MailIcon from "./Mail";
import BlocksIcon from "./Blocks";
import MagnifierIcon from "./Magnifier";

const Icons = {
  title: "Atom/Icons",
};

export default Icons;

export const Instagram = () => <InstagramIcon color="#f00" />;
export const Linkedin = () => <LinkedinIcon color="#f00" />;
export const Facebook = () => <FacebookIcon color="#f00" />;
export const LeftArrow = () => <LeftArrowIcon color="#f00" />;
export const Mail = () => <MailIcon color="#f00" />;
export const Blocks = () => <BlocksIcon color="#f00" />;
export const Magnifier = () => <MagnifierIcon color="#f00" />;
