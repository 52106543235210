/* eslint-disable no-multi-str */
import React from 'react'
import styles from './styles.module.scss';
import SquareButton from '../SquareButton';
import { Magnifier } from '../../libs/icons';

const JobNotFound = () => {
  const isMobile = window.innerWidth <= 1024;
  const image = isMobile
    ? 'https://s3.sa-east-1.amazonaws.com/public.inklua/not_found_mobile.webp'
    : 'https://s3.sa-east-1.amazonaws.com/public.inklua/not_found.webp'
  const imageAlt = 'Mulher negra de cabelo azul com dred. <br> \
    Ela está com uma camisa laranja e um short verde claro. <br/> \
    Ela tem uma perna mecanica e esta fazendo uma pose de dúvida';

  return (
    <div className={isMobile ? styles.mobileWrapper :  styles.desktopWrapper}>
      <img src={image} alt={imageAlt} />
      <h1 className={styles.title}>
        <b>Essa vaga </b>
        fechou!
      </h1>
      <div className={styles.text}>
        <p>Mas temos diversas vagas esperando por você.</p>
        <p>Quem sabe uma delas é sua próxima <br/> oportunidade!</p>
      </div>
      <SquareButton
        text="Ver outras vagas"
        type="submit"
        icon={<Magnifier color="#fff" />}
        onClick={() => window.location.href = '/busca'}
      />
    </div>
  )
};

export default JobNotFound;