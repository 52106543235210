import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './styles.module.scss';
import { Input } from '../../components/NewInput';
import { Form } from '@unform/web';
import SquareButton from '../../components/SquareButton';
import { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import useAxios from '../../hooks/useAxios';
import { useSnackBarContext } from '../../contexts/SnackBar';

const RegisterPassword = () => {
  const formRef = useRef(null);
  const history = useHistory();
  const { token } = useParams();
  const { exec, loading } = useAxios();
  const { showSnackBar } = useSnackBarContext();

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});
      
      showSnackBar({
        timeout: 5000,
        text: 'Validando dados...',
        loading: true
      });

      const schema = Yup.object().shape({
        password: Yup.string()
          .min(8, 'Deve ter no mínimo 8 caracteres')
          .required('Senha obrigatória'),
        'confirm-password': Yup.string()
          .oneOf([Yup.ref('password'), null], 'As senhas não coincidem')
          .required('Confirmação de senha obrigatória')
      });

      await schema.validate(data, {
        abortEarly: false
      });

      const response = await exec({
        method: 'POST',
        url: '/v2/register/candidate/password',
        data: {
          password: btoa(`${token}:${data?.password}`)
        },
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 403) {
        return history.push('/');
      }

      if (response.error) {
        formRef.current.setErrors({ password: response.message });
        return showSnackBar({
          timeout: 3000,
          text: 'Erro ao cadastrar senha, tente novamente em instantes!',
          error: true
        });
      }

      formRef.current.setErrors({});

      showSnackBar({
        timeout: 3000,
        text: 'Senha cadastrada com sucesso. Redirecionando...',
        success: true
      });

      return history.push('/');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const tokenValidation = async () => {
    if (!token) return history.push('/');

    const response = await exec({
      method: 'GET',
      url: `/v2/register/candidate/check-token/${token}`
    });

    if (response.status === 403) {
      showSnackBar({
        timeout: 2000,
        text: 'Falha ao validar token. Faça o cadastro novamente!',
        error: true
      });

      return setTimeout(() => {
        history.push('/autenticacao/cadastro/candidato');
      }, 2000);
    }
  };

  useEffect(() => { tokenValidation() }, []);

  return (
    <div className={styles.wrapper}>
      <Form ref={formRef} className={styles.form} onSubmit={handleSubmit}>
        <h2>
          Digite uma nova <br/>
          <b>senha para sua conta</b>
        </h2>
        <div className={styles.inputBox}>
          <Input
            label='Nova senha'
            name='password'
            type='password'
            placeholder='Digite sua senha'
          />
          <Input
            label='Confirme a senha'
            name='confirm-password'
            type='password'
            placeholder='Confirme a senha'
          />
          <SquareButton
            disabled={loading}
            text='Concluir'
            type='submit'
            dataTest='signup-submit'
          />
        </div>
      </Form>
    </div>
  )
};

export default RegisterPassword;