import React from "react";

import AuthenticationWrapper from "../../components/AuthenticationWrapper";
import AcceptTheTerms from "../../components/AcceptTheTerms";

const AgreeWithTerms = () => {
  return (
    <AuthenticationWrapper>
      <AcceptTheTerms />
    </AuthenticationWrapper>
  );
};

export default AgreeWithTerms;
