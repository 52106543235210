import { string } from "prop-types";
import { useSnackBarContext } from "../../contexts/SnackBar";
import styles from "./styles.module.scss";
import { Share } from '../../libs/icons';

const UserInformationWithAvatar = ({ path }) => {
  const { showSnackBar } = useSnackBarContext();
  const isMobile = window.innerWidth <= 1024;

  const handleShare = () => {
    if (navigator.clipboard && window.location) {
      return navigator
        .clipboard
        .writeText(path)
        .then(() => {
          showSnackBar({
            timeout: 2500,
            text: 'Url do perfil copiado!',
            success: true
          });
        })
        .catch(() => {
          showSnackBar({
            timeout: 2500,
            text: 'Erro ao tentar copiar a url do perfil.',
            error: true
          });
        });
    }
  };

  return (
    <div 
      className={styles.wrapper}
      style={ isMobile ? { gap: '0', flexDirection: 'column', alignItems: 'flex-start' } : {} }
    >
      <span className={styles.title}>Meu Perfil</span>
      <div className={styles.share} onClick={handleShare}>
        <Share />
        <p>Compartilhar</p>
      </div>
    </div>
  )
};

UserInformationWithAvatar.propTypes = {
  primaryText: string.isRequired,
  secondaryText: string,
};

UserInformationWithAvatar.defaultProps = {
  secondaryText: null,
};

export { UserInformationWithAvatar };
