import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Home from "./screens/Home";
import Contact from "./screens/Contact";
import About from "./screens/About";
import Position from "./screens/Position";
import Search from "./screens/Search";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import {
  SignIn,
  CandidateSignUp,
  ForgotPassword,
  AgreeWithTerms,
  ValidateYourEmail,
  ConfirmYourEmail,
  ResendPasswordReset,
  DefineNewPassword,
  RecruiterSignUp
} from "./modules/authentication/screens";
import {
  CreateListing,
  CreateListingScnd,
  MyListings,
  MyListingsScnd,
  ViewListing,
  ViewListingDetail,
  CandidateProfile,
  CandidateReport,
  ApproveCandidate,
  RejectCandidate,
} from "./modules/Recruiter/screens";
import {
  CandidatePersonalData,
  CandidateDisability,
  CandidateAvailability,
  CandidateExperiences,
  CandidateDegree,
  CandidateLanguages,
  CandidateAddLanguage,
  CandidateAddExperience,
  OnboardingEditExperience,
  CandidateAddEducation,
  OnboardingEditEducation,
  CandidateWelcome,
  CandidateFullySignedUp,
  CandidateIndication,
} from "./modules/Candidate/screens";
import { UserInfo } from "./modules/others/screens";
import {
  EditInformation,
  ChangePassword,
  MyAccount,
  CandidateEditPersonalData,
  CandidateListEducation,
  CandidateEditCourse,
  CandidateEditLanguage,
  CandidateEditAvailability,
  CandidateEditDisability,
  CandidateAddCourse,
  CandidateAddNewExperience,
  CandidateListExperience,
  CandidateEditExperience,
} from "./modules/Account/screens";

//Screens
import HowItWorks from "./screens/HowItWorks";
import CurrentCause from "./screens/CurrentCause";
import TechMap from "./screens/TechMap";
import Details from "./screens/CandidateDetails";

//Enterprise
import EnterpriseHome from "./modules/Enterprise/screens/Home";
import Recruitment from "./modules/Enterprise/screens/Recruitment";
import Lecture from "./modules/Enterprise/screens/Lecture";
import DetailsEnterprise from "./modules/Enterprise/screens/CandidateDetails";
import SignInEnterprise from "./modules/Enterprise/screens/SignIn";
import { RecruiterSignUpEnterprise } from "./modules/Enterprise/screens/RecruiterSignUp";
import ForgotPasswordEnterprise from "./modules/Enterprise/screens/ForgotPassword";
import ValidateYourEmailEnterprise from "./modules/Enterprise/authentication/ValidateYourEmail";
import ContactEnterprise from "./modules/Enterprise/screens/Contact";
import ResendPasswordResetEnterprise from "./modules/Enterprise/authentication/ResendPasswordReset";
import MyAccountEnterprise from "./modules/Enterprise/Account/screens/MyAccount";
import EditInformationEnterprise from "./modules/Enterprise/Account/screens/EditInformation";
import ChangePasswordEnterprise from "./modules/Enterprise/Account/screens/ChangePassword";
import DefineNewPasswordEnterprise from "./modules/Enterprise/authentication/DefineNewPassword";
import AgreeWithTermsEnterprise from "./modules/Enterprise/authentication/AgreeWithTerms";
import PrivacyPolicyEnterprise from "./modules/Enterprise/screens/PrivacyPolicy";

//Components
import NavigationMonitor from "./components/NavigationMonitor";
import CustomRoute from "./components/CustomRoute";
import ShareModal from "./components/ShareModal";
import SnackBar from "./components/SnackBar";

import { ModalProvider } from "./contexts/Modal";
import { ShareProvider } from "./contexts/Share";
import { SnackBarProvider } from "./contexts/SnackBar";
import { UserProvider } from "./contexts/User";
import ConfirmYourEmailEnterprise from "./modules/Enterprise/authentication/ConfirmYourEmail";
import RegisterPassword from './screens/RegisterPassword';

const Providers = ({ children }) => (
  <SnackBarProvider>
    <ShareProvider>
      <ModalProvider>
        <UserProvider>{children}</UserProvider>
      </ModalProvider>
    </ShareProvider>
  </SnackBarProvider>
);

function App() {
  return (
    <Providers>
      <BrowserRouter basename="/">
        <SnackBar />
        <ShareModal />
        <NavigationMonitor />
        <Switch>
          <CustomRoute exact path="/" component={Home} />
          <CustomRoute exact path="/mapeamento-tech" component={TechMap} />
          <CustomRoute exact path="/mapeamento-tech/:id" component={Details} />
          <CustomRoute path="/quem-somos" component={About} />
          <CustomRoute
            path="/para-empresas"
            component={EnterpriseHome}
            options={{
              headerVersion: "enterprise",
              footerVersion: "enterprise",
            }}
            exact
          />
          <CustomRoute
            path="/para-empresas/solucoes-rs/:id?"
            component={Recruitment}
            options={{
              headerVersion: "enterprise",
              footerVersion: "enterprise",
            }}
          />
          <CustomRoute
            path="/para-empresas/palestras"
            component={Lecture}
            options={{
              headerVersion: "enterprise",
              footerVersion: "enterprise",
            }}
          />
          <CustomRoute
            path="/para-empresas/contato"
            component={ContactEnterprise}
            options={{
              headerVersion: "enterprise",
              footerVersion: "enterprise",
            }}
          />
          <CustomRoute path="/contato" component={Contact} />
          <CustomRoute path="/vaga/:slug/:id" component={Position} />
          <CustomRoute
            exact
            path="/busca"
            component={Search}
          />
          <CustomRoute
            exact
            path="/politica-de-privacidade"
            component={PrivacyPolicy}
            options={{
              headerVersion: "simple",
            }}
          />
          <CustomRoute
            exact
            path="/para-empresas/politica-de-privacidade"
            component={PrivacyPolicyEnterprise}
            options={{
              headerVersion: "simpleEnterprise",
              footerVersion: "enterprise",
            }}
          />
          <CustomRoute
            exact
            path="/autenticacao/login"
            component={SignIn}
            options={{
              headerVersion: "simple",
            }}
          />
          <CustomRoute
            exact
            path="/para-empresas/autenticacao/login"
            component={SignInEnterprise}
            options={{
              headerVersion: "simpleEnterprise",
              footerVersion: "enterprise",
            }}
          />
          <CustomRoute
            exact
            path="/autenticacao/cadastro/candidato"
            component={CandidateSignUp}
            options={{
              headerVersion: "simple",
            }}
          />
          <CustomRoute
            exact
            path="/autenticacao/cadastro/candidato/dados-pessoais"
            component={CandidatePersonalData}
            options={{
              authenticated: true,
            }}
          />
          {/* <CustomRoute
            exact
            path="/autenticacao/cadastro/candidato/bem-vindo"
            component={CandidateWelcome}
            options={{
              authenticated: true,
            }}
          /> */}
          <CustomRoute
            exact
            path="/autenticacao/cadastro/candidato/deficiencia"
            component={CandidateDisability}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/autenticacao/cadastro/candidato/disponibilidade"
            component={CandidateAvailability}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/autenticacao/cadastro/candidato/experiencia"
            component={CandidateExperiences}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/autenticacao/cadastro/candidato/experiencia/adicionar"
            component={CandidateAddExperience}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/autenticacao/cadastro/candidato/experiencia/editar/:id"
            component={OnboardingEditExperience}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/autenticacao/cadastro/candidato/formacao-academica"
            component={CandidateDegree}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/autenticacao/cadastro/candidato/formacao-academica/adicionar"
            component={CandidateAddEducation}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/autenticacao/cadastro/candidato/formacao-academica/editar/:id"
            component={OnboardingEditEducation}
            options={{
              authenticated: true,
            }}
          />
          {/* <CustomRoute
            exact
            path="/autenticacao/cadastro/candidato/idiomas"
            component={CandidateLanguages}
            options={{
              authenticated: true,
            }}
          /> */}
          <CustomRoute
            exact
            path="/autenticacao/cadastro/candidato/idiomas/adicionar"
            component={CandidateAddLanguage}
            options={{
              authenticated: true,
            }}
          />
          {/* Caso a tela de indicação retorne, descomentar as linhas abaixo */}
          {/* <CustomRoute
            exact
            path="/autenticacao/cadastro/candidato/indicacao"
            component={CandidateIndication}
            options={{
              authenticated: true,
            }}
          /> */}
          <CustomRoute
            exact
            path={[
              "/autenticacao/cadastro/candidato/cadastro-completo-pcd",
              "/autenticacao/cadastro/candidato/cadastro-completo",
            ]}
            component={CandidateFullySignedUp}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/para-empresas/autenticacao/cadastro/recrutador"
            component={RecruiterSignUpEnterprise}
            options={{
              headerVersion: "simpleEnterprise",
              footerVersion: "enterprise",
            }}
          />
          <CustomRoute
            exact
            path="/autenticacao/esqueci-minha-senha"
            component={ForgotPassword}
          />
          <CustomRoute
            exact
            path="/para-empresas/autenticacao/esqueci-minha-senha"
            component={ForgotPasswordEnterprise}
            options={{
              headerVersion: "simpleEnterprise",
              footerVersion: "enterprise",
            }}
          />
          {/* <CustomRoute
            exact
            path="/autenticacao/confirme-seu-email"
            component={ValidateYourEmail}
          /> */}
          <CustomRoute
            exact
            path="/para-empresas/autenticacao/confirme-seu-email"
            component={ValidateYourEmailEnterprise}
            options={{
              headerVersion: "simpleEnterprise",
              footerVersion: "enterprise",
            }}
          />
          {/* <CustomRoute
            exact
            path="/autenticacao/validar-email"
            component={ConfirmYourEmail}
          /> */}
          <CustomRoute
            exact
            path="/para-empresas/autenticacao/validar-email"
            component={ConfirmYourEmailEnterprise}
            options={{
              headerVersion: "simpleEnterprise",
              footerVersion: "enterprise",
            }}
          />
          <CustomRoute
            exact
            path="/autenticacao/reenviar-nova-senha"
            component={ResendPasswordReset}
          />
          <CustomRoute
            exact
            path="/para-empresas/autenticacao/reenviar-nova-senha"
            component={ResendPasswordResetEnterprise}
            options={{
              headerVersion: "simpleEnterprise",
              footerVersion: "enterprise",
            }}
          />
          <CustomRoute
            exact
            path="/autenticacao/definir-nova-senha"
            component={DefineNewPassword}
          />
          <CustomRoute
            exact
            path="/para-empresas/autenticacao/definir-nova-senha"
            component={DefineNewPasswordEnterprise}
            options={{
              headerVersion: "simpleEnterprise",
              footerVersion: "enterprise",
            }}
          />
          <CustomRoute
            exact
            path="/autenticacao/aceite-os-termos-de-uso"
            component={AgreeWithTerms}
          />
          <CustomRoute
            exact
            path="/para-empresas/autenticacao/aceite-os-termos-de-uso"
            component={AgreeWithTermsEnterprise}
            options={{
              headerVersion: "simpleEnterprise",
              footerVersion: "enterprise",
            }}
          />
          <CustomRoute
            exact
            path="/candidato/minha-conta"
            component={MyAccount}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/minha-conta/dados-pessoais"
            component={CandidateEditPersonalData}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/minha-conta/deficiencia"
            component={CandidateEditDisability}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/minha-conta/disponibilidade"
            component={CandidateEditAvailability}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/candidato/minha-conta/formacao-academica"
            component={CandidateListEducation}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/candidato/minha-conta/formacao-academica/editar/:id"
            component={CandidateEditCourse}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/autenticacao/cadastro/criar-senha/:token"
            component={RegisterPassword}
            options={{
              authenticated: false,
            }}
          />
          <CustomRoute
            exact
            path="/candidato/minha-conta/formacao-academica/adicionar"
            component={CandidateAddCourse}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/candidato/minha-conta/experiencia"
            component={CandidateListExperience}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/candidato/minha-conta/experiencia/editar/:id"
            component={CandidateEditExperience}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/candidato/minha-conta/experiencia/adicionar"
            component={CandidateAddNewExperience}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute exact path="/minha-conta" component={MyAccount} />
          <CustomRoute
            exact
            path="/para-empresas/minha-conta"
            component={MyAccountEnterprise}
            options={{
              headerVersion: "enterprise",
              footerVersion: "enterprise",
            }}
          />
          <CustomRoute
            exact
            path="/candidato/minha-conta/idioma/:id"
            component={CandidateEditLanguage}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/para-empresas/minha-conta/editar"
            component={EditInformationEnterprise}
            options={{
              authenticated: true,
              headerVersion: "enterprise",
              footerVersion: "enterprise",
            }}
          />
          <CustomRoute
            exact
            path="/minha-conta/editar"
            component={EditInformation}
            options={{
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/minha-conta/alterar-senha"
            component={ChangePassword}
            options={{
              headerVersion: "simple",
              authenticated: true,
            }}
          />
          <CustomRoute
            exact
            path="/para-empresas/minha-conta/alterar-senha"
            component={ChangePasswordEnterprise}
            options={{
              authenticated: true,
              headerVersion: "simpleEnterprise",
              footerVersion: "enterprise",
            }}
          />
          <CustomRoute
            path={[
              "/para-empresas/recrutador/criar-vaga/:action/:slug/:id",
              "/para-empresas/recrutador/criar-vaga",
            ]}
            component={CreateListingScnd}
            options={{
              authenticated: true,
              headerVersion: "enterprise",
              footerVersion: "enterprise",
            }}
          />
          <CustomRoute
            exact
            path="/para-empresas/recrutador/minhas-vagas"
            component={MyListingsScnd}
            options={{
              authenticated: true,
              headerVersion: "enterprise",
              footerVersion: "enterprise",
            }}
          />
          {/* <CustomRoute exact path="/como-funciona" component={HowItWorks} /> */}
          {/* <CustomRoute exact path="/area-de-doacoes" component={CurrentCause} /> */}
          <CustomRoute exact path="/debug/user-info" component={UserInfo} />
          <CustomRoute exact path="/mapeamento-tech" component={TechMap} />
          <CustomRoute
            exact
            path="/para-empresas/mapeamento-tech"
            component={TechMap}
            options={{
              headerVersion: "enterprise",
              footerVersion: "enterprise",
            }}
          />
          <CustomRoute
            exact
            path="/para-empresas/mapeamento-tech/:id"
            component={DetailsEnterprise}
            options={{
              headerVersion: "enterprise",
              footerVersion: "enterprise",
            }}
          />
          {/* <CustomRoute
            exact
            path="*"
            component={() => <Redirect to="/" />}
          /> */}
        </Switch>
      </BrowserRouter>
    </Providers>
  );
}

export default App;
