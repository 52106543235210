import { string } from "prop-types";

import { UserInformationWithAvatar } from "../../../../components/UserInformationWithAvatar";

const WelcomeMessage = ({ name, ...rest }) => (
  <UserInformationWithAvatar primaryText={name} secondaryText="Bem vindo(a)" {...rest} />
);

WelcomeMessage.propTypes = {
  name: string.isRequired,
};

export default WelcomeMessage;
