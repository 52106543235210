import { string, number, node, oneOfType } from "prop-types";

import useAxios from "../../hooks/useAxios";
import { SimpleInformation } from "../../modules/Recruiter/components/SimpleInformation";
import { NewInformationSummary } from "../../modules/Account/components/NewInformationSummary";

import styles from "./styles.module.scss";

const PersonalDataSummary = ({
  name,
  lastname,
  city,
  state,
  salary,
  age,
  birthDate,
  gender,
  race,
  cellphone,
  cellphoneLink,
  email,
  updatedAt,
  linkedinUrl,
  portfolioUrl,
  resumeUrl,
  actionButton,
  neighborhood,
}) => {
  const local = neighborhood ? `${neighborhood},` : '';
  const { exec, loading } = useAxios();

  const handleOpenFile = async (url) => {
    try {
      const response = await exec({
        useCredentials: true,
        method: "GET",
        url: url,
      });

      window.open(response.url,'_blank')
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <NewInformationSummary title="Dados pessoais">
      <ul>
        <li>
          <SimpleInformation
            title="Nome"
            value={`${name} ${lastname}`}
            testID="user-fullname"
          />
        </li>
        {(city && state) && (
          <li>
            <SimpleInformation
              title="Local"
              value={`${local} ${city} - ${state}` || 'Não Preenchido'}
              testID="user-location"
            />
          </li>
        )}
        <li>
          <SimpleInformation
            title="Pretensão salarial"
            value={salary || 'Não Preenchido'}
            testID="user-salary"
          />
        </li>
        {age && (
          <li>
            <SimpleInformation title="Idade" value={age || 'Não preenchido'} testID="user-age" />
          </li>
        )}
        {birthDate && (
          <li>
            <SimpleInformation
              title="Data nasc."
              value={birthDate}
              testID="user-birth-date"
            />
          </li>
        )}
        <li>
          <SimpleInformation
            title="Gênero"
            value={gender || 'Não Preenchido'}
            testID="user-gender"
          />
        </li>
        <li>
          <SimpleInformation title="Cor/Raça" value={race || 'Não Preenchido'} testID="user-race" />
        </li>
        <li>
          <SimpleInformation
            title="Telefone"
            value={cellphone || 'Não Preenchido'}
            testID="user-phone"
            url={cellphoneLink || ''}
          />
        </li>
        <li>
          <SimpleInformation title="E-mail" value={email} testID="user-email" />
        </li>
        <li>
          {resumeUrl ? (
            <SimpleInformation
              title="Currículo"
              onClick={() => handleOpenFile(resumeUrl)}
              value="Visualizar"
              testID="resume-info"
              loading={loading}
            />
          ) : (
            <SimpleInformation
              title="Currículo"
              value="-"
              testID="resume-info"
            />
          )}
        </li>
        {updatedAt && (
          <li>
            <SimpleInformation title="Atualizado em" value={updatedAt || 'Não Preenchido'} />
          </li>
        )}
        <li>
          {linkedinUrl ? (
            <SimpleInformation
              title="Linkedin"
              url={linkedinUrl}
              value="acessar"
              testID="linkedin-info"
            />
          ) : (
            <SimpleInformation
              title="Linkedin"
              value="-"
              testID="linkedin-info"
            />
          )}
        </li>
        <li>
          {portfolioUrl ? (
            <SimpleInformation
              title="Portfólio"
              url={portfolioUrl}
              value="acessar"
              testID="portfolio-info"
            />
          ) : (
            <SimpleInformation
              title="Portfólio"
              value="-"
              testID="portfolio-info"
            />
          )}
        </li>
      </ul>
      <div className={styles.actionButtonWrapper}>{actionButton}</div>
    </NewInformationSummary>
  );
};

PersonalDataSummary.propTypes = {
  name: string.isRequired,
  lastname: string.isRequired,
  city: string,
  state: string,
  salary: oneOfType([number, string]).isRequired,
  age: number,
  birthDate: string,
  gender: string.isRequired,
  race: string.isRequired,
  cellphone: string.isRequired,
  email: string.isRequired,
  updatedAt: string,
  linkedinUrl: string,
  portfolioUrl: string,
  resumeUrl: string,
  actionButton: node,
};

PersonalDataSummary.defaultProps = {
  age: null,
  birthDate: null,
  city: null,
  state: null,
  updatedAt: null,
  linkedinUrl: null,
  portfolioUrl: null,
  resumeUrl: null,
  actionButton: null,
};

export { PersonalDataSummary };
