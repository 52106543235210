/* eslint-disable no-useless-escape */

import React, { useRef } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";

import { Input } from "../../../../components/NewInput";
import SquareButton from "../../components/SquareButton";
import SectionTitle from "../../../../components/SectionTitle";
import { BooleanCheckbox } from "../../../../components/NewInput";
import useAxios from "../../../../hooks/useAxios";
import { useUserContext } from "../../../../contexts/User";
import validationDictionary from "../../../../constants/validationDictionary";

import FieldsGrid from "../../../authentication/components/FieldsGrid";
import { parseEmail, ValidateCNPJ } from "../../../../helpers";

import styles from './styles.module.scss';
import { useSnackBarContext } from "../../../../contexts/SnackBar";

const RecruiterSignUpForm = () => {
  const formRef = useRef(null);
  const { exec, loading } = useAxios();
  const history = useHistory();
  const { updateInkoins, setUserData } = useUserContext();
  const { showSnackBar } = useSnackBarContext();


  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});
      data.phone = data.phone.replace(/[^0-9]/g, "");

      const schema = Yup.object().shape({
        cnpj: Yup.string()
          .test({
            message: validationDictionary.INVALID_CNPJ,
            test: () => ValidateCNPJ(data.cnpj),
          })
          .min(14, validationDictionary.INVALID_CNPJ)
          .max(18, validationDictionary.INVALID_CNPJ)
          .required(validationDictionary.INVALID_CNPJ),
        fantasy_name: Yup.string()
          .min(3, validationDictionary.INVALID_COMPANY_NAME)
          .required(validationDictionary.INVALID_COMPANY_NAME),
        corporate_name: Yup.string()
          .min(3, validationDictionary.INVALID_COMPANY_NAME)
          .required(validationDictionary.INVALID_COMPANY_NAME),
        name: Yup.string()
          .min(3, validationDictionary.INVALID_NAME)
          .required(validationDictionary.INVALID_NAME),
        lastname: Yup.string()
          .min(3, validationDictionary.INVALID_LASTNAME)
          .required(validationDictionary.INVALID_LASTNAME),
        phone: Yup.string()
          .min(10, validationDictionary.INVALID_PHONE)
          .max(11, validationDictionary.INVALID_PHONE)
          .required(validationDictionary.INVALID_PHONE),
        email: Yup.string()
          .transform(parseEmail)
          .email(validationDictionary.INVALID_EMAIL)
          .required(validationDictionary.INVALID_EMAIL),
        password: Yup.string()
          .min(8, validationDictionary.PASSWORD_MIN_CHARACTERS)
          .required(validationDictionary.INVALID_PASSWORD),
        password_confirmation: Yup.string().oneOf(
          [Yup.ref("password"), null],
          validationDictionary.PASSWORDS_DO_NOT_MATCH
        ),
        accepted_terms: Yup.bool().oneOf(
          [true],
          validationDictionary.TERMS_ARE_REQUIRED
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await exec({
        method: "POST",
        url: "/user/register",
        data: { ...data, type: "PJ", email: parseEmail(data.email) },
      });

      if (response.error || response.status === 406) {
        showSnackBar({
          timeout: 5000,
          text: "E-mail ou senha incorretos.",
          error: true
        });
      };

      if (response.token && response.user) {
        setUserData(response.user);
        localStorage.setItem("@user_token", response.token);
        localStorage.setItem("@user_data", JSON.stringify(response.user));

        formRef.current.reset();

        // history.push("/para-empresas/autenticacao/confirme-seu-email");

        return;
      }

      const errors = response.data.errors;
      const validationErrors = {};

      Object.keys(errors).forEach((err) => {
        validationErrors[err] = errors[err][0];
      });

      formRef.current.setErrors(validationErrors);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      id="contact-form"
      className={styles.wrapper}
    >
      <SectionTitle
        small
        centered
        level="h2"
        children="Crie sua conta <b>na Inklua</b>"
      />
      <FieldsGrid>
        <Input
          label="CNPJ"
          name="cnpj"
          placeholder="Digite o CNPJ da empresa"
          type="text"
          mask="99.999.999/9999-99"
        />
        <Input
          label="Nome Fantasia"
          name="fantasy_name"
          placeholder="Digite o nome fantasia da empresa"
          type="text"
        />
        <Input
          label="Razão Social"
          name="corporate_name"
          placeholder="Digite a razão social da empresa"
          type="text"
        />
        <Input
          label="Nome"
          name="name"
          placeholder="Digite seu nome"
          type="text"
        />
        <Input
          label="Sobrenome"
          name="lastname"
          placeholder="Digite seu sobrenome"
          type="text"
        />
        <Input
          label="Telefone"
          name="phone"
          placeholder="Digite seu telefone"
          type="text"
          mask="(99) 99999-9999"
        />
        <Input
          label="E-mail"
          name="email"
          placeholder="Digite seu e-mail"
          type="text"
        />
        <Input
          label="Senha"
          name="password"
          placeholder="Digite sua senha"
          type="password"
        />
        <Input
          label="Confirme a senha"
          name="password_confirmation"
          placeholder="Confirme sua senha"
          type="password"
        />
        <BooleanCheckbox name="accepted_terms">
          Li e aceito os{" "}
          <Link to="/para-empresas/politica-de-privacidade">
            Termos e condições de uso
          </Link>
        </BooleanCheckbox>
      </FieldsGrid>
      <SquareButton disabled={loading} text="Criar conta" type="submit" />
    </Form>
  );
};

export default RecruiterSignUpForm;
