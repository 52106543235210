import { node } from "prop-types";

import styles from "./styles.module.scss";

const AccountCardContainer = ({ children, smallPadding }) => {
  const isMobile = window.innerWidth <= 1024;
  const changePadding = isMobile && smallPadding;

  return (
    <div
      className={styles.wrapper}
      data-testid="account-card-container"
      style={changePadding ? { padding: '.5rem' } : {}}
    >
      {children}
    </div>
  );
};

AccountCardContainer.propTypes = {
  children: node.isRequired,
};

export default AccountCardContainer;
