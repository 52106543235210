import React from 'react';
import styles from './styles.module.scss';
import Loader from '../../libs/icons/Loader.js';
import Info from '../../libs/icons/Info.js';
import Trophy from '../../libs/icons/Trophy.js';

const ResumeProcessingStatus = ({ status }) => {
  const isMobile = window.innerWidth <= 1024;
  const statusObj = {
    waiting: {
      icon: () => <Loader color='#FA8A4B' />,
      text: 'Aguarde enquanto montando o seu perfil',
      color: '#FA8A4B'
    },
    'missing-data': {
      icon: () => <Info color='#D72E2F'/>,
      text: 'Ainda faltam algumas informações sobre você',
      color: '#D72E2F'
    },
    complete: {
      icon: () => <Trophy color='#09AD4D' />,
      text: 'Seu perfil está completo',
      color: '#09AD4D'
    }
  }

  if (!status || !statusObj[status]) return null;

  const { icon, text, color } = statusObj[status];

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {icon()}
        <p style={{ color: color, fontSize: isMobile ? '.90rem' : 'initial' }}>{text}</p>
      </div>
    </div>
  )
};

export default ResumeProcessingStatus;