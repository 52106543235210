import React, { useState } from "react";
import styles from './styles.module.scss';

const FileUpload = ({
  name,
  label,
  setFile,
  formatsAllowed,
  placeholder
}) => {
  const [fileURL, setFileURL] = useState(null);
  const [fileName, setFileName] = useState(null);

  function handleFileChange(event) {
    const file = event.target.files && event.target.files[0];
    setFileName(file.name);
    const url = URL.createObjectURL(file);
    setFileURL(url);
    setFile(file);
  }

  return (
    <div className={styles.inputFileContent}>
      <p>{label?.toUpperCase()}</p>
      <div className={[styles.inputFile, fileURL && styles.uploadedFile].join(' ')}>
        {fileURL &&
          <label htmlFor={`upload-file-${name}`}>
            <span>
              {fileName}
              <p>clique para alterar o anexo</p>
            </span>
            <input
              id={`upload-file-${name}`}
              type="file"
              onChange={handleFileChange}
              accept={formatsAllowed}
            />
          </label>
        }
        {!fileURL && (
          <label htmlFor={`upload-file-${name}`}>
            <span>{placeholder}</span>
            <input
              id={`upload-file-${name}`}
              type="file"
              onChange={handleFileChange}
              accept={formatsAllowed}
            />
          </label>
        )}
      </div>
      <span>Formatos: {formatsAllowed.toUpperCase()}</span>
    </div>
  );
};

export { FileUpload };