import { string, oneOf } from "prop-types";

import { ForbiddenCircle, WarningTriangle } from "../../../../libs/icons";

import styles from "./styles.module.scss";

const iconMap = {
  BLOCKED: <ForbiddenCircle color="#fff" />,
  TAKEN: <WarningTriangle color="#fff" />,
};

const CandidateRecruitmentStatusMobile = ({ recruiterName, status }) => {
  const renderText = () => {
    if (recruiterName && status === "TAKEN") {
      return `Candidato está em processo com ${recruiterName}`;
    }

    if (status === "BLOCKED") {
      return "Não considerar este candidato";
    }
  };

  if (status === "YOURS" || status === "AVAILABLE") {
    return null;
  }

  return (
    <div
      className={[
        styles.wrapper,
        status === "TAKEN" ? styles.taken : "",
        status === "BLOCKED" ? styles.blocked : "",
      ].join(" ")}
    >
      <span className={styles.iconWrapper}>{iconMap[status]}</span>
      <span data-testid="text" className={styles.text}>
        {renderText()}
      </span>
    </div>
  );
};

CandidateRecruitmentStatusMobile.propTypes = {
  status: oneOf(["TAKEN", "YOURS", "BLOCKED", "AVAILABLE"]).isRequired,
  recruiterName: string,
};

CandidateRecruitmentStatusMobile.defaultProps = {
  recruiterName: null,
};

export { CandidateRecruitmentStatusMobile };
