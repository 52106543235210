import React, { useRef, useEffect, useState } from "react";
import {
  arrayOf,
  shape,
  func,
  string,
  bool,
  oneOfType,
  number,
} from "prop-types";
import { useField } from "@unform/core";
import { Chevron } from "../../libs/icons";
import styles from "./styles.module.scss";

const SelectInput = ({
  setter,
  options,
  name,
  label,
  keys,
  placeholder,
  defaultValue,
  disabled,
  hidePlaceholder,
  error
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, clearError } = useField(name);
  const [isDirty, setIsDirty] = useState(false);

  const handleOnChange = (event) => {
    setIsDirty(true);

    clearError();
    const { value } = event.target;
    setter(value);

    event.target.style.color = '#222b45';
  };

  const selectedOption = defaultValue
    ? options.find((state) => state[keys.value] === defaultValue)[keys.value]
    : "null";


  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <div className={styles.wrapper}>
      <label
        className={styles.label}
        htmlFor={`${name}-field`}
        data-testid={`${name}-label`}
      >
        {label}
      </label>
      {error && (
        <span
          className={styles.error}
          id={`error_${name}`}
          data-testid={`error-message-${name}`}
        >
          {typeof error === 'string' ? error : 'Selecione uma opção'}
        </span>
      )}
      <select
        onChange={handleOnChange}
        className={[
          styles.field,
          error ? styles.isInvalid : "",
          selectedOption === "null" && !isDirty ? styles.empty : "",
        ].join(" ")}
        id={`${name}-field`}
        defaultValue={selectedOption || options[0][keys.value]}
        data-testid={`${name}-field`}
        disabled={disabled}
      >
        <option
          disabled={defaultValue}
          selected={!defaultValue}
          hidden={hidePlaceholder}
          value=""
          data-testid={`${name}-field-placeholder`}
        >
          {placeholder}
        </option>
        {options.map((option) => (
          <option
            key={`option_${option[keys.value]}`}
            value={option[keys.value]}
            data-testid={`${name}-field-option`}
          >
            {option[keys.label]}
          </option>
        ))}
      </select>
      <Chevron color="#8893ab" />
    </div>
  );
};

SelectInput.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  setter: func.isRequired,
  placeholder: string.isRequired,
  keys: shape({
    label: string.isRequired,
    value: string.isRequired,
  }).isRequired,
  options: arrayOf(shape().isRequired).isRequired,
  disabled: bool,
  defaultValue: oneOfType([string, number]),
  hidePlaceholder: bool,
};

SelectInput.defaultProps = {
  hidePlaceholder: true,
  disabled: false,
  defaultValue: null,
};

export default SelectInput;
