import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { User } from "../../libs/icons";
import { lockScroll } from "../../helpers";
import IconLink from "../../components/IconLink";
import SearchField from "../../components/SearchField";
import Menu from "../../components/Menu";
import MenuTrigger from "../../components/MenuTrigger";
// import InkoinCounter from "../../components/InkoinCounter";
import SearchBlockWrapper from "../../components/SearchBlockWrapper";

import styles from "./styles.module.scss";
import { bool } from "prop-types";

const Header = ({ hideSearch }) => {
  const [isFloating, setFloating] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userData, setUserData] = useState();
  const [tooltipVisibility, setTooltipVisibility] = useState(false);

  const handleScroll = () => {
    setFloating(window.scrollY > 0);
  };

  useEffect(() => {
    lockScroll(isMenuOpen);

    return () => {
      lockScroll(false);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    const userDataCache = localStorage.getItem("@user_data");
    if (userDataCache) {
      setUserData(JSON.parse(userDataCache));
      const { email } = JSON.parse(userDataCache);
      if (localStorage.getItem(`@user_viewed_tooltip_${email}`)) {
        return;
      }

      setTooltipVisibility(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <span className={styles.spacer} aria-hidden="true" />
      <header
        className={[styles.wrapper, isFloating && styles.floating].join(" ")}
      >
        <div className={styles.container}>
          <Link to="/" aria-label="Início">
            <img
              width="130px"
              src="https://s3.sa-east-1.amazonaws.com/public.inklua/logo.svg"
              alt="Logomarca da Inklua, onde cada letra possui cor verde clara e
                  a letra A possui detalhes na cor azul escuro, laranja e amarelo"
            />
          </Link>
          {!hideSearch && <SearchField />}
          <div className={styles.links}>
            {/* {localStorage.getItem("@user_token") && <InkoinCounter />} */}
            {(userData?.name && (localStorage.getItem("@user_token") || localStorage.getItem("@user_data"))) 
              && (
                <Link className={styles.userName} to='/candidato/minha-conta'>
                  <User color="#1B9B9E" hidden />
                  {userData?.name}
                </Link>
              )
            }
            {!localStorage.getItem("@user_token") && (
              <IconLink
                to="/autenticacao/login"
                text="Entrar"
                type="link"
                dataTest="login"
              >
                <User color="#1B9B9E" hidden />
              </IconLink>
            )}
            <MenuTrigger
              changeWidth={hideSearch}
              onClick={() => setIsMenuOpen(true)}
              loggedInUser={!!userData?.name}
            />
          </div>
        </div>
      </header>
      <Menu visible={isMenuOpen} handleClose={() => setIsMenuOpen(false)} />
      <SearchBlockWrapper type="mobile" />
    </>
  );
};

Header.propTypes = {
  hideSearch: bool,
};

export default Header;
