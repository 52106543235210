import React from "react";
import { string } from "prop-types";

const Loader = ({ color }) => {
  return (
		<svg
      width="24"
      height="24"
    >
			<path 
        fill={color}
        transform="translate(2 2)"
        d="M10 0C9.448 0 9 0.447 9 1L9 3C9 3.553 9.448 4 10 4C10.552 4 11 3.553 11 3L11 1C11 0.447 10.552 0 10 0M19 9L17 9C16.448 9 16 9.4469995 16 10C16 10.553 16.448 11 17 11L19 11C19.552 11 20 10.553 20 10C20 9.4469995 19.552 9 19 9M4 10C4 9.4469995 3.552 9 3 9L1 9C0.44800001 9 0 9.4469995 0 10C0 10.553 0.44800001 11 1 11L3 11C3.552 11 4 10.553 4 10M4.2206998 3.0283C3.8237 2.6443 3.1917 2.6573 2.8067 3.0532999C2.4226999 3.4503 2.4337001 4.0843 2.8306999 4.4672999L4.2697001 5.8572998C4.4647002 6.0453 4.7147002 6.1373 4.9647002 6.1373C5.2266998 6.1373 5.4877 6.0352998 5.6837001 5.8323002C6.0676999 5.4352999 6.0567002 4.8013 5.6596999 4.4183002L4.2206998 3.0283ZM15.0352 6.1377001C15.2852 6.1377001 15.5352 6.0447001 15.7302 5.8576999L17.169201 4.4677C17.5662 4.0837002 17.5772 3.4497001 17.193199 3.0537C16.8102 2.6577001 16.1782 2.6447001 15.7792 3.0287001L14.3402 4.4176998C13.9432 4.8017001 13.9322 5.4356999 14.3162 5.8316998C14.5122 6.0346999 14.7732 6.1377001 15.0352 6.1377001M10 16C9.448 16 9 16.447001 9 17L9 19C9 19.552999 9.448 20 10 20C10.552 20 11 19.552999 11 19L11 17C11 16.447001 10.552 16 10 16M15.73 14.1426C15.333 13.7596 14.7 13.7716 14.316 14.1676C13.932 14.5646 13.943 15.1986 14.34 15.5816L15.779 16.9716C15.974 17.159599 16.224001 17.2516 16.474001 17.2516C16.736 17.2516 16.997 17.149599 17.193001 16.9466C17.577 16.549601 17.566 15.9156 17.169001 15.5326L15.73 14.1426ZM4.27 14.1426L2.8310001 15.5326C2.434 15.9156 2.4230001 16.549601 2.8069999 16.9466C3.003 17.149599 3.2639999 17.2516 3.526 17.2516C3.776 17.2516 4.026 17.159599 4.2210002 16.9716L5.6599998 15.5816C6.0570002 15.1986 6.0679998 14.5646 5.684 14.1676C5.3010001 13.7716 4.6680002 13.7596 4.27 14.1426"
        fill-rule="evenodd"/>
		</svg>
  );
};

Loader.propTypes = {
  color: string
};

Loader.defaultProps = {
  color: "#1B9B9E"
};

export default Loader;
