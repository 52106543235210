import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import getUserData from "../helpers/getUserData";

const useDoubleAuthPrevention = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const validate = async ({ redirect = true }) => {
    setLoading(true);
    const data = await getUserData();

    // if (data && !data.accepted_terms) {
    //   history.push("/autenticacao/aceite-os-termos-de-uso");
    //   return;
    // }

    // if (data && !data.email_verified_at) {
    //   history.push("/autenticacao/confirme-seu-email");
    //   return;
    // }

    if (data && redirect) {
      history.push("/");
    }
  };

  useEffect(() => {
    return () => setLoading(false);
  }, []);

  return { loading, validate };
};

export default useDoubleAuthPrevention;
