import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AuthenticationWrapper from "../../../authentication/components/AuthenticationWrapper";
import { focusElement } from "../../../../helpers";
import Illustration from "./assets/illustration.svg";
import styles from "./styles.module.scss";

const CandidateFullySignedUp = () => {
  const history = useHistory();
  const [counter, setCounter] = useState(4);

  useEffect(() => {
    const timer = setTimeout(() => {
      history.push("/#vagas");
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    focusElement('[data-testid="onboarding-complete-image"]');
  }, []);

  return (
    <>
      <AuthenticationWrapper>
        <img
          tabIndex="0"
          src={Illustration}
          data-testid="onboarding-complete-image"
          alt="Ilustração de um grupo de pessoas com deficiência juntas. Da esquerda para a direita tem um rapaz com deficiência auditiva, de pele clara, cabelos castanhos, usa uma camiseta branca de manga curta e calça verde claro, com a mão esquerda ele está fazendo o sinal de positivo e com a mão direita está apoiada no ombro de um cadeirante. Este cadeirante é um rapaz mulato, de cabelos castanhos, também usa uma camiseta branca e calça verde claro. Ao lado deste cadeirante tem uma mulher que possui deficiência visual, ela tem pele branca, cabelos loiros de comprimento médio, usa um óculo escuro e um vestido azul escuro, com a mão direita ela segura uma bengala guia e com a mão esquerda ele abraça outra mulher. Esta última mulher é deficiente física, usa uma prótese no braço esquerdo, ela é mulata e tem cabelos azuis, usa uma camiseta branca e calça verde claro, com a mão direita ela apoia no ombro da outra mulher descrita anteriormente. Todos estão felizes e sorrindo!"
          className={styles.image}
        />
        <h2 className={styles.title}>
          Cadastro finalizado
          <br />
          <b>com sucesso!</b>
        </h2>
        <p className={styles.redirectText}>
          Redirecionando você para tela de vagas <br/> em <b>{counter}</b> segundos...
        </p>
      </AuthenticationWrapper>
    </>
  );
};

export { CandidateFullySignedUp };
