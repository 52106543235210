import { node } from "prop-types";

import styles from "./styles.module.scss";

const Container = ({ children, error = false }) => {
  return <section className={`${styles.wrapper} ${error && styles.error}`}>{children}</section>;
};

Container.propTypes = {
  children: node.isRequired,
};

export { Container };
