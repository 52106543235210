import React, { useRef } from 'react';
import styles from './styles.module.scss';
import AutoCompleteInput from '../AutoCompleteInput';
import cities from '../../assets/json/cities.json';
import { Form } from '@unform/web';
import SquareButton from '../SquareButton';
import SimpleRadioInput from '../SimpleRadioInput';
import { CurrencyInput, Input } from '../NewInput';
import * as Yup from 'yup';
import useAxios from '../../hooks/useAxios';
import { useSnackBarContext } from "../../contexts/SnackBar";

const MissingInfo = ({ missingData, showMissingInfo, reFetch }) => {
  const formRef = useRef();
  const parentRef = useRef();
  const { exec, loading } = useAxios();
  const { showSnackBar } = useSnackBarContext();

  const hasString = (str) => str && missingData?.includes(str);
  const strToBool = (str) => {
    return str === null ? null : !!(str === 'true')
  };
  const booleanValidation = (fieldName) => Yup.boolean()
    .required(`Escolha se aceita ${fieldName}`)
    .test('is-not-null', `Escolha se aceita ${fieldName}`, (value) => value !== null)
    .oneOf([true, false], `Escolha se aceita ${fieldName}`);

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});

      const getIdFromCities = (id) => Number(cities.find(city => city.name === data.city)[id]);

      if (data.local || data.remote || data.hybrid || data['move-out']) {
        data.local = strToBool(data?.local)
        data.remote = strToBool(data?.remote)
        data.hybrid = strToBool(data?.hybrid)
        data['move-out'] = strToBool(data['move-out'])
      }

      const schema = Yup.object().shape({
        city: Yup.string()
          .test('city', 'Adicione sua cidade', (value) => {
            if (value !== undefined) {
              return Yup.string().required().isValidSync(value);
            }
            return true;
          }),
        birthdate: Yup.string()
          .test('birthdate', 'Adicione sua data de nascimento', (value) => {
            if (value !== undefined) {
              return Yup.string().required().isValidSync(value);
            }
            return true;
          }),
        salary: Yup.number()
          .test('salary', 'Adicione sua pretensão salarial', (value) => {
            if (value !== undefined) {
              return Yup.number()
                .test('is-not-zero', 'Pretensão salarial não pode ser 0', (value) => value !== 0)
                .required()
                .isValidSync(value);
            }
            return true;
          }),
        local: Yup.boolean()
          .test('local', 'Escolha se aceita trabalhar presencial', (value) => {
            if (value !== undefined) {
              return booleanValidation('trabalhar presencial').isValidSync(value);
            }
            return true;
          }),
        remote: Yup.boolean()
          .test('remote', 'Escolha se aceita trabalhar remoto', (value) => {
            if (value !== undefined) {
              return booleanValidation('trabalhar remoto').isValidSync(value);
            }
            return true;
          }),
        hybrid: Yup.boolean()
          .test('hybrid', 'Escolha se aceita trabalhar hibrido', (value) => {
            if (value !== undefined) {
              return booleanValidation('trabalhar hibrido').isValidSync(value);
            }
            return true;
          }),
        'move-out': Yup.boolean()
          .test('move-out', 'Escolha se aceita mudar de cidade', (value) => {
            if (value !== undefined) {
              return booleanValidation('mudar de cidade').isValidSync(value);
            }
            return true;
          }),
      });

      await schema.validate(data, {
        abortEarly: false
      });

      if (data.local || data.remote || data.hybrid || data['move-out']) {
        data.availability = {
          local: data.local,
          remote: data.remote,
          hybrid: data.hybrid,
          'move_out': data['move-out']
        };

        delete data.local;
        delete data.remote;
        delete data.hybrid;
        delete data['move-out'];
      }

      if (data.birthdate) data.birthdate = data?.birthdate?.split('/').reverse().join('-');
      if (data.city) {
        data.state = getIdFromCities('states_id')
        data.city = getIdFromCities('id');
      }
      if (data.salary) data.salary = Number(data.salary);

      const response = await exec({
        method: 'PUT',
        url: `/v2/profile/missing-data`,
        useCredentials: true,
        data
      });

      if (response.error) {
        showSnackBar({
          timeout: 5000,
          text: 'Erro ao salvar informações, tente novamente em instantes.',
          error: true
        });

        const errors = response.error.message;
        const validationErrors = {};

        Object.keys(errors).forEach((err) => {
          validationErrors[err] = errors[err][0];
        });

        formRef.current.setErrors(validationErrors);
        formRef.current.reset();

        return;
      }

      showSnackBar({
        timeout: 5000,
        text: 'Informações salvas com sucesso!',
        success: true
      });

      formRef.current.setErrors({});
      formRef.current.reset();

      reFetch();

      return showMissingInfo(false);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      autoComplete="off"
      className={styles.wrapper}
    >
      <h1>Faltam algumas informações importantes que precisamos saber sobre você!</h1>
      <div className={styles.inputBox}>
        {(hasString('city') || hasString('state')) && (
          <AutoCompleteInput
            label='Qual cidade você mora'
            placeholder='Digite o nome da cidade'
            name='city'
            fetch={() => {}}
            setter={() => {}}
            options={cities.map((city) => city?.name)}
          />
        )}
        {hasString('birthdate') && (
          <Input
            label='Data de nascimento'
            placeholder='Insira sua data de nascimento'
            name='birthdate'
            type='text'
            mask='99/99/9999'
          />
        )}
        {hasString('salary') && (
          <CurrencyInput
            label='Pretensão salarial'
            placeholder='Insira sua pretensão salarial'
            name='salary'
          />
        )}
      </div>
      {hasString('availability') && (
        <div ref={parentRef} className={styles.radioBox}>
          <SimpleRadioInput
            label='Aceita trabalhar presencial?'
            name='local'
            parentRef={parentRef}
          />
          <SimpleRadioInput
            label='Aceita trabalhar remoto?'
            name='remote'
            parentRef={parentRef}
          />
          <SimpleRadioInput
            label='Aceita trabalhar hibrido?'
            name='hybrid'
            parentRef={parentRef}
          />
          <SimpleRadioInput
            label='Possuí disponibilidade para mudança?'
            name='move-out'
            parentRef={parentRef}
          />
        </div>
      )}
      <SquareButton
        text="Salvar"
        type="submit"
        testID="submit-personal-data-button"
      />
    </Form>
  )
};

export default MissingInfo;
