import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import PositionDescription from "../../components/PositionDescription";
import useAxios from "../../hooks/useAxios";
import { toDate, stripHTML } from "../../helpers";
import SectionTitle from "../../components/SectionTitle";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import JobNotFound from '../../components/JobNotFound';

const MetaTags = ({
  location,
  jobTitle,
  description,
  salary,
  url
}) => (
  <Helmet>
    <title>{`Vaga para PcD: ${jobTitle} – ${location} | Inklua`}</title>
    <meta name="description" content={`${description}... Remuneração: ${salary}`} />
    <meta name="keywords" content={`vaga de emprego pcd, emprego para pcd, vaga para pcd, vaga pcd Brasil, oportunidade de emprego pcd, oportunidade, trabalho, recrutamento e seleção, cadastrar currículo, curriculo, Inklua, vagas na Inklua, inkua, empresa inklua, PcD, ${jobTitle}, ${location}.`} />
    <meta property="og:title" content={`${jobTitle} | Inklua`} />
    <meta property="og:type" content="website" />
    <meta property="og:description" content={`${description}... Remuneração: ${salary}`} />
    <meta property="og:image" content="https://s3.sa-east-1.amazonaws.com/public.inklua/logo_mini.png" />
    <meta property="og:image:type" content="image/png" />
    <meta property="og:image:width" content="200" />
    <meta property="og:image:height" content="200" />
    <meta property="og:url" content={`${jobTitle} | Inklua`} />
    <meta property="og:site_name" content={`${jobTitle} | Inklua`} />
    <link rel="canonical" href={url} />
  </Helmet>
);

const Position = () => {
  const { id } = useParams();
  const [contentData, setContentData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const { exec, loading } = useAxios();
  const userData = localStorage.getItem("@user_data")
    ? JSON.parse(localStorage.getItem("@user_data"))
    : undefined;
  const isUserRecruiter = userData?.type === 'PJ';

  const fetchJobLiked = async () => {
    if (!!localStorage.getItem("@user_token")) {
      const response = await exec({
        useCredentials: true,
        method: "get",
        url: `/vaga/${id}/liked`,
      });
      return response.liked
    }
    return false
  };

  const handleCompleoUrl = (url) => {
    if (url) return url.replace("/Visualizar/", "/PreCandidatar/");
  };

  const fetchData = async () => {
    const response = await exec({
      useCredentials: false,
      method: "get",
      url: `/vaga/${id}`,
    });

    const jobResponse = { ...response, liked: await fetchJobLiked(id) }

    setContentData(jobResponse);
    setLoading(false);
  };

  const parseDescriptionToMetatags = (string) => {
    if (!string) return '';

    return stripHTML(string).slice(0, 120);
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || loading) return <LoadingSpinner />

  if (contentData.error) return <JobNotFound />;
  // if (contentData.error) return <SectionTitle centered level="h2">Vaga não encontrada!</SectionTitle>;

  return (
    <>
      <MetaTags
        jobTitle={contentData.title}
        location={contentData.location ?? ' - '}
        description={parseDescriptionToMetatags(contentData.description)}
        salary={contentData.salary}
        url={window.location.href}
      />
      <PositionDescription
        title={contentData.title}
        company={contentData.company}
        date={contentData.published}
        location={contentData.location}
        presential={contentData.contract_type.some(item => item === 'local')}
        hybrid={contentData.contract_type.some(item => item === 'hybrid')}
        remote={contentData.contract_type.some(item => item === 'remote')}
        benefits={contentData.benefits}
        requirements={contentData.requirements}
        application={contentData.application}
        applicationType={contentData.application_type}
        salary={contentData.salary}
        text={contentData.description}
        url={handleCompleoUrl(contentData.url)}
        id={Number(id)}
        shares={contentData.shares}
        illustrationUrl={contentData.image}
        illustrationAltText={contentData.alt_image}
        shift={contentData.hours}
        englishLevel={contentData.english_level}
        recruiterName={contentData.recruiter}
        alreadyLiked={contentData.liked}
        disableLike={isUserRecruiter}
        compleoJob={contentData.source === "compleo"}
        fetchUserData={fetchData}
      />
    </>
  );
};

export default Position;
